import React, { useState, useContext } from "react";
import * as Yup from "yup";
import "./style.css";
import { navigate } from "gatsby";
import { Formik, Field, Form } from "formik";
import { useMutation } from "@apollo/react-hooks";
import { generateToken } from "../graphql/mutations";
import { toast } from "react-toastify";
import { LanguageContext } from "../../../lang";

const EmailValidate = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please Enter Your Email"),
});

const intialValue = {
  email: "",
};

const ValidateEmail = (props) => {
  const [generateTokenTrigger, { loading, data, error }] = useMutation(
    generateToken
  );
  const [email, setEmail] = useState("");
  const handleValidateEmail = (values) => {
    setEmail(values.email);
    generateTokenTrigger({
      variables: {
        data: {
          email: values.email,
        },
      },
    });
  };
  if (data) {
    const { generateResetTokenCustomer } = data ? data : {};
    navigate("/validate-verification-code", {
      state: {
        message: generateResetTokenCustomer,
        email,
      },
    });
    console.log("response returned from graphql", data);
  }
  if (error) {
    toast.error(error.message, { autoClose: 1200 });
  }
  if (loading) {
    console.log("loading returned from graphql", loading);
  }

  const {
    translations: { login },
  } = useContext(LanguageContext);

  const {
    emailVerifyHeading,
    emailVerifyMsg,
    emailVerifyPlaceholder,
    emailVerifyBtn,
  } = login;

  return (
    <div className="container-fluid d-flex hm-login-bg justify-content-center align-items-center">
      <div id="forgotPassword">
        <div className="forgotPasswordTextView">
          <p>{emailVerifyHeading}</p>
        </div>
        <div className="instructionText">
          <p>{emailVerifyMsg}</p>
        </div>
        <Formik
          initialValues={intialValue}
          validationSchema={EmailValidate}
          onSubmit={(values) => handleValidateEmail(values)}
        >
          {({ values, errors, touched, handleSubmit }) => (
            <div className="inputstyleview">
              <Form className="formStyle">
                <Field
                  className="emailStyle"
                  type="text"
                  name="email"
                  placeholder={emailVerifyPlaceholder}
                />
                {errors.email && touched.email ? (
                  <div className="bg-danger my-1 p-0 col-12 rounded">
                    <p className="text-white p-1 m-0">{errors.email}</p>
                  </div>
                ) : null}
              </Form>
              <div onClick={handleSubmit} className="loginButtonStyle">
                <p className="loginButtonText">{emailVerifyBtn}</p>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export { ValidateEmail };
