import gql from 'graphql-tag'

export const generateToken = gql`
	mutation($data: ResetPasswordTokenInputCustomer!) {
		generateResetTokenCustomer(data: $data)
	}
`
export const validateToken = gql`
	mutation($data: ValidatePasswordTokenInputCustomer!) {
		validateResetTokenCustomer(data: $data)
	}
`

export const changePassword = gql`
	mutation($data: ChangePasswordInputCustomer!) {
		changePasswordCustomer(data: $data)
	}
`
